define("discourse/plugins/stemaway-ui-addons/discourse/connectors/before-topic-list/category-search-bar", ["exports", "@ember/component", "@ember/service", "@ember/runloop"], function (_exports, _component, _service, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    siteSettings: (0, _service.inject)(),
    searchService: (0, _service.inject)('search-service'),
    didInsertElement() {
      this._super(...arguments);
      this.searchService.fetchCurrentCategoryDetails();
      this.handleOutsideClick = event => {
        const inputField = this.element.querySelector('input[type="text"]');
        const dropdown = this.element.querySelector('.search-results-dropdown');
        if (inputField && !inputField.contains(event.target) && (!dropdown || dropdown && !dropdown.contains(event.target))) {
          this.searchService.resetSearch();
        }
      };
      document.addEventListener('click', this.handleOutsideClick);
    },
    willDestroyElement() {
      this._super(...arguments);
      document.removeEventListener('click', this.handleOutsideClick);
    },
    actions: {
      updateSearchQuery(value) {
        (0, _runloop.debounce)(this, this.performSearch, value, 500);
      }
    },
    performSearch(value) {
      if (this.searchService.currentCategoryTopicCount > 10) {
        this.get('searchService').performSearch(value);
      }
    }
  });
});