define("discourse/plugins/stemaway-ui-addons/discourse/services/search-service", ["exports", "@ember/service", "@glimmer/tracking", "discourse/lib/ajax", "discourse/models/category"], function (_exports, _service, _tracking, _ajax, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "searchQuery", [_tracking.tracked], function () {
      return '';
    }))();
    #searchQuery = (() => (dt7948.i(this, "searchQuery"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "searchResults", [_tracking.tracked], function () {
      return [];
    }))();
    #searchResults = (() => (dt7948.i(this, "searchResults"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "searchPerformed", [_tracking.tracked], function () {
      return false;
    }))();
    #searchPerformed = (() => (dt7948.i(this, "searchPerformed"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "currentCategoryTopicCount", [_tracking.tracked], function () {
      return null;
    }))();
    #currentCategoryTopicCount = (() => (dt7948.i(this, "currentCategoryTopicCount"), void 0))();
    currentSearchId = 0;
    constructor() {
      super(...arguments);
      this.resetSearch();
      this.router.on('routeDidChange', () => {
        this.resetSearch();
      });
    }
    resetSearch() {
      this.searchQuery = '';
      this.searchResults = [];
      this.isLoading = false;
      this.searchPerformed = false;
    }
    fetchCurrentCategoryDetails() {
      const currentRoute = this.router.currentRoute;
      if (!currentRoute || !currentRoute.attributes || !currentRoute.attributes.category) {
        console.warn("No category found in the current route.");
        return;
      }
      let currentCategoryId = currentRoute.attributes.category.id;
      if (!currentCategoryId) {
        console.warn("Category ID not found.");
        return;
      }
      (0, _ajax.ajax)(`/c/${currentCategoryId}`).then(response => {
        this.currentCategoryTopicCount = response.topic_list.topics.length;
      }).catch(error => {
        console.error("Error fetching category details:", error);
      });
    }
    performSearch(searchQuery) {
      this.searchQuery = searchQuery;
      if (this.searchQuery.trim().length === 0) {
        this.resetSearch();
        return;
      }
      this.isLoading = true;
      this.searchPerformed = true;
      const searchId = ++this.currentSearchId;
      let currentCategoryId = this.router.currentRoute.attributes.category.id;
      (0, _ajax.ajax)(`/custom_search?search_query=${encodeURIComponent(this.searchQuery.trim())}&category_id=${currentCategoryId}`).then(response => {
        if (searchId !== this.currentSearchId) {
          return;
        }
        this.isLoading = false;
        if (response && response.custom_search.length > 0) {
          this.searchResults = response.custom_search.map(result => ({
            ...result,
            tagsJoined: (result.tags || []).join(', ')
          }));
        } else {
          this.searchResults = [];
        }
      }).catch(error => {
        console.error("Search error:", error);
        if (searchId !== this.currentSearchId) {
          return;
        }
        this.isLoading = false;
        this.searchResults = [];
      });
    }
  }
  _exports.default = SearchService;
});