define("discourse/plugins/stemaway-ui-addons/discourse/controllers/admin-plugins-t3-admin", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "discourse/lib/ajax"], function (_exports, _controller, _object, _tracking, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserAnalyticsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "topicScores", [_tracking.tracked], function () {
      return {};
    }))();
    #topicScores = (() => (dt7948.i(this, "topicScores"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "selectedTopic", [_tracking.tracked], function () {
      return null;
    }))();
    #selectedTopic = (() => (dt7948.i(this, "selectedTopic"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "filteredUsers", [_tracking.tracked], function () {
      return [];
    }))();
    #filteredUsers = (() => (dt7948.i(this, "filteredUsers"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "filterStatus", [_tracking.tracked], function () {
      return '';
    }))();
    #filterStatus = (() => (dt7948.i(this, "filterStatus"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "errorMessage", [_tracking.tracked], function () {
      return '';
    }))();
    #errorMessage = (() => (dt7948.i(this, "errorMessage"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "showScores", [_tracking.tracked], function () {
      return true;
    }))();
    #showScores = (() => (dt7948.i(this, "showScores"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "showPopup", [_tracking.tracked], function () {
      return false;
    }))();
    #showPopup = (() => (dt7948.i(this, "showPopup"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "popupContent", [_tracking.tracked], function () {
      return null;
    }))();
    #popupContent = (() => (dt7948.i(this, "popupContent"), void 0))();
    init() {
      super.init(...arguments);
    }
    get hasTopicScores() {
      return Object.keys(this.topicScores).length > 0;
    }
    get currentUsernames() {
      return this.filteredUsers.map(user => user.username).join(', ');
    }
    fetchAllUserTopicScores() {
      this.isLoading = true;
      this.errorMessage = '';
      (0, _ajax.ajax)('/admin/user/custom_search/fetch-all-user-topic-scores', {
        method: 'GET'
      }).then(result => {
        this.topicScores = result.topic_scores;
        this.isLoading = false;
      }).catch(error => {
        this.errorMessage = error.message;
        this.isLoading = false;
      });
    }
    static #_12 = (() => dt7948.n(this.prototype, "fetchAllUserTopicScores", [_object.action]))();
    selectTopic(topicId) {
      this.selectedTopic = topicId;
      this.fetchEvaluationScore(topicId);
    }
    static #_13 = (() => dt7948.n(this.prototype, "selectTopic", [_object.action]))();
    fetchEvaluationScore(topicId) {
      (0, _ajax.ajax)(`/admin/user/custom_search/fetch-evaluation-score?topic_id=${topicId}`, {
        method: 'GET'
      }).then(result => {
        if (result.evaluations) {
          console.log(result.evaluations);
          this.topicScores[topicId].users.forEach(user => {
            let evaluation = result.evaluations[user.username];
            if (evaluation) {
              user.evaluationExists = true;
              user.evaluation = evaluation[0];
            } else {
              user.evaluationExists = false;
            }
          });
          this.filterUsers();
        } else {
          console.error('No evaluations found');
        }
      }).catch(error => {
        console.error('Error fetching evaluation scores:', error);
      });
    }
    static #_14 = (() => dt7948.n(this.prototype, "fetchEvaluationScore", [_object.action]))();
    searchUsername(event) {
      let searchValue = event.target.value.toLowerCase();
      this.filterUsers(searchValue);
    }
    static #_15 = (() => dt7948.n(this.prototype, "searchUsername", [_object.action]))();
    filterByStatus(event) {
      this.filterStatus = event.target.value;
      this.filterUsers();
    }
    static #_16 = (() => dt7948.n(this.prototype, "filterByStatus", [_object.action]))();
    toggleShowScores() {
      this.showScores = !this.showScores;
    }
    static #_17 = (() => dt7948.n(this.prototype, "toggleShowScores", [_object.action]))();
    copyUsernamesToClipboard() {
      navigator.clipboard.writeText(this.currentUsernames).then(() => {
        alert('Usernames copied to clipboard');
      }).catch(error => {
        console.error('Error copying usernames to clipboard:', error);
      });
    }
    static #_18 = (() => dt7948.n(this.prototype, "copyUsernamesToClipboard", [_object.action]))();
    showEvaluationLogs(user) {
      if (user.evaluation) {
        const evaluationFields = [{
          label: "Technical Proficiency",
          value: user.evaluation.technical_proficiency
        }, {
          label: "Problem Solving",
          value: user.evaluation.problem_solving
        }, {
          label: "Innovation",
          value: user.evaluation.innovation
        }, {
          label: "Adaptability",
          value: user.evaluation.adaptability
        }, {
          label: "Initiative",
          value: user.evaluation.initiative
        }, {
          label: "Communication",
          value: user.evaluation.communication
        }];
        const feedback = user.evaluation.feedback.split(/(\d+\.\s+)/).filter(Boolean);
        let feedbackHtml = feedback.map((text, index) => {
          if (/^\d+\.\s*$/.test(text)) {
            return `<p><strong>${text}</strong>`;
          } else {
            return `${text}</p>`;
          }
        }).join('');
        let contentHtml = evaluationFields.map(field => {
          return `<li><strong>${field.label}:</strong> ${field.value}</li>`;
        }).join('');
        contentHtml += `<li><strong>Feedback:</strong><div class="qualitative-feedback">${feedbackHtml}</div></li>`;
        this.popupContent = `
        <h4>Evaluation Logs for ${user.username}</h4>
        <ul>${contentHtml}</ul>
      `;
        this.showPopup = true;
      } else {
        this.popupContent = `<p>No evaluation data available for ${user.username}.</p>`;
        this.showPopup = true;
      }
    }
    static #_19 = (() => dt7948.n(this.prototype, "showEvaluationLogs", [_object.action]))();
    closePopup() {
      this.showPopup = false;
      this.popupContent = null;
    }
    static #_20 = (() => dt7948.n(this.prototype, "closePopup", [_object.action]))();
    filterUsers() {
      let searchValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      if (!this.selectedTopic) {
        this.filteredUsers = [];
        return;
      }
      let selectedTopicData = this.topicScores[this.selectedTopic];
      if (!selectedTopicData || !selectedTopicData.users) {
        this.filteredUsers = [];
        return;
      }
      this.filteredUsers = selectedTopicData.users.filter(user => {
        let matchesSearch = user.username.toLowerCase().includes(searchValue);
        let matchesStatus = true;
        if (this.filterStatus === 'pass') {
          matchesStatus = parseInt(user.score.split('/')[0]) >= this.siteSettings.evaluation_required_score;
        } else if (this.filterStatus === 'fail') {
          matchesStatus = parseInt(user.score.split('/')[0]) < this.siteSettings.evaluation_required_score;
        }
        return matchesSearch && matchesStatus;
      });
    }
  }
  _exports.default = UserAnalyticsController;
});