define("discourse/plugins/stemaway-ui-addons/discourse/helpers/calculate-score-style", ["exports", "@ember/component/helper", "@ember/template"], function (_exports, _helper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateScoreStyle = calculateScoreStyle;
  _exports.default = void 0;
  function calculateScoreStyle(_ref) {
    let [score] = _ref;
    const [obtained, total] = score.split('/').map(Number);
    const percentage = obtained / total * 100;
    let color;
    if (percentage <= 50) {
      color = `rgb(${255},${percentage * 5.1},0)`;
    } else {
      color = `rgb(${(100 - percentage) * 5.1},220,0)`;
    }
    return (0, _template.htmlSafe)(`width: ${percentage}%; background-color: ${color};`);
  }
  var _default = _exports.default = (0, _helper.helper)(calculateScoreStyle);
});