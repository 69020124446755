define("discourse/plugins/stemaway-ui-addons/discourse/components/analytics", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    isActivityTabActive: true,
    actions: {
      toggleTab(tab) {
        this.set('isActivityTabActive', tab === 'activity');
      }
    }
  });
});