define("discourse/plugins/stemaway-ui-addons/discourse/stemaway-ui-addons-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "user",
    map() {
      this.route("analytics", {
        resetNamespace: true
      });
    }
  };
});