define("discourse/plugins/stemaway-ui-addons/discourse/helpers/tacks-change-icon", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.tacksChangeIcon = tacksChangeIcon;
  function tacksChangeIcon(_ref) {
    let [change] = _ref;
    if (change > 0) {
      return 'icon-up-arrow';
    } else if (change < 0) {
      return 'icon-down-arrow';
    } else {
      return 'icon-minus';
    }
  }
  var _default = _exports.default = (0, _helper.helper)(tacksChangeIcon);
});