define("discourse/plugins/stemaway-ui-addons/discourse/templates/connectors/before-topic-list/category-search-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.show_search_bar}}
    {{#if (gt this.searchService.currentCategoryTopicCount 10)}}
      <div class="topic-search-bar-container">
        <input type="text" value={{this.searchService.searchQuery}} placeholder="Search topics..." oninput={{action "updateSearchQuery" value="target.value"}}>
        {{#if this.searchService.isLoading}}
          <div class="search-results-dropdown">
            <div class="loading-spinner"></div>
          </div>
        {{else if this.searchService.searchResults.length}}
          <div class="search-results-dropdown">
            <ul>
          {{#each this.searchService.searchResults as |result|}}
            <li>
              <a href="{{result.url}}" target="_blank">{{clean-text result.title}}</a>
              <br>
              <small class="category-name">{{result.category_name}}</small>
              <small class="tag-list">{{result.tagsJoined}}</small>
            </li>
          {{/each}}
  
            </ul>
          </div>
        {{else if this.searchService.searchPerformed}}
          <div class="search-results-dropdown">
            <div class="no-results-message">No results found.</div>
          </div>
        {{/if}}
      </div>
    {{/if}}
  {{/if}}
  */
  {
    "id": "J/GnRF9P",
    "block": "[[[41,[30,0,[\"siteSettings\",\"show_search_bar\"]],[[[41,[28,[37,1],[[30,0,[\"searchService\",\"currentCategoryTopicCount\"]],10],null],[[[1,\"    \"],[10,0],[14,0,\"topic-search-bar-container\"],[12],[1,\"\\n      \"],[10,\"input\"],[15,2,[30,0,[\"searchService\",\"searchQuery\"]]],[14,\"placeholder\",\"Search topics...\"],[15,\"oninput\",[28,[37,2],[[30,0],\"updateSearchQuery\"],[[\"value\"],[\"target.value\"]]]],[14,4,\"text\"],[12],[13],[1,\"\\n\"],[41,[30,0,[\"searchService\",\"isLoading\"]],[[[1,\"        \"],[10,0],[14,0,\"search-results-dropdown\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"loading-spinner\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"searchService\",\"searchResults\",\"length\"]],[[[1,\"        \"],[10,0],[14,0,\"search-results-dropdown\"],[12],[1,\"\\n          \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"searchService\",\"searchResults\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[12],[1,\"\\n            \"],[10,3],[15,6,[29,[[30,1,[\"url\"]]]]],[14,\"target\",\"_blank\"],[12],[1,[28,[35,5],[[30,1,[\"title\"]]],null]],[13],[1,\"\\n            \"],[10,\"br\"],[12],[13],[1,\"\\n            \"],[10,\"small\"],[14,0,\"category-name\"],[12],[1,[30,1,[\"category_name\"]]],[13],[1,\"\\n            \"],[10,\"small\"],[14,0,\"tag-list\"],[12],[1,[30,1,[\"tagsJoined\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"searchService\",\"searchPerformed\"]],[[[1,\"        \"],[10,0],[14,0,\"search-results-dropdown\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"no-results-message\"],[12],[1,\"No results found.\"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],null]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[\"result\"],false,[\"if\",\"gt\",\"action\",\"each\",\"-track-array\",\"clean-text\"]]",
    "moduleName": "discourse/plugins/stemaway-ui-addons/discourse/templates/connectors/before-topic-list/category-search-bar.hbs",
    "isStrictMode": false
  });
});