define("discourse/plugins/stemaway-ui-addons/discourse/controllers/analytics", ["exports", "@ember/controller", "discourse/lib/ajax", "@ember/service", "@ember/object", "@glimmer/tracking", "@ember/template", "discourse/lib/utilities"], function (_exports, _controller, _ajax, _service, _object, _tracking, _template, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AnalyticsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    logs = null;
    topicScores = null;
    evaluations = null;
    userAttempts = null;
    points = 0;
    showPopup = false;
    popupContent = null;
    static #_3 = (() => dt7948.g(this.prototype, "expandedSkills", [_tracking.tracked], function () {
      return {};
    }))();
    #expandedSkills = (() => (dt7948.i(this, "expandedSkills"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "isDropdownOpen", [_tracking.tracked], function () {
      return false;
    }))();
    #isDropdownOpen = (() => (dt7948.i(this, "isDropdownOpen"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "chartData", [_tracking.tracked], function () {
      return '';
    }))();
    #chartData = (() => (dt7948.i(this, "chartData"), void 0))();
    subcategoriesAndTopics = null;
    static #_6 = (() => dt7948.g(this.prototype, "subcategoryTopicData", [_tracking.tracked], function () {
      return [];
    }))();
    #subcategoryTopicData = (() => (dt7948.i(this, "subcategoryTopicData"), void 0))();
    errorMessage = null;
    constructor() {
      super(...arguments);
      this.router.addObserver('currentRouteName', this, this.routeChanged);
    }
    routeChanged() {
      if (this.router.currentRouteName === 'analytics') {
        if (this.currentUser.username === this.model.username || this.currentUser.admin) {
          this.loadAllData();
        }
      }
      this.isDropdownOpen = false;
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.router.removeObserver('currentRouteName', this, this.routeChanged);
    }
    async loadAllData() {
      this.loadUserPoints();
      this.loadLogs();
      await this.fetchSubcategoriesAndTopics();
      await this.fetchUserAttempts();
      await this.loadScoresAndEvaluations();
      this.generateSubcategoryTopicData();
    }
    loadUserPoints() {
      if (!this.model?.username) {
        return;
      }
      (0, _ajax.ajax)(`/user-points/${this.model.username}`).then(data => this.set('points', data.points)).catch(() => {
        this.set('points', 0);
        console.error('Error fetching user points');
      });
    }
    loadLogs() {
      if (!this.model?.username) {
        return;
      }
      (0, _ajax.ajax)(`/custom_search/logs/${this.model.username}`).then(data => {
        this.set('logs', data.logs);
        let totalTacksUsed = data.logs.filter(log => log.points_change < 0).reduce((acc, log) => acc + Math.abs(log.points_change), 0);
        this.set('totalTacksUsed', totalTacksUsed);
        this.generateChartData(data.logs);
      }).catch(error => console.error('Error fetching user logs:', error));
    }
    fetchUserAttempts() {
      if (!this.model?.username) {
        return;
      }
      return (0, _ajax.ajax)(`/custom_search/fetch-all-user-attempts/${this.model.username}`).then(response => {
        if (response.userAttempts) {
          this.set('userAttempts', response.userAttempts);
        } else {
          this.set('userAttempts', {});
        }
      }).catch(() => {
        this.set('userAttempts', {});
      });
    }
    async loadScoresAndEvaluations() {
      await this.fetchAllTopicScores();
      await this.fetchEvaluationFeedback();
      if (this.topicScores && this.evaluations) {
        for (let topicId in this.topicScores) {
          if (this.evaluations.hasOwnProperty(topicId)) {
            this.topicScores[topicId].evaluation = this.evaluations[topicId].evaluations;
            this.topicScores[topicId].evaluationVisibility = this.evaluations[topicId].evaluations[0].visibility;
          }
        }
      }
    }
    async fetchSubcategoriesAndTopics() {
      try {
        const data = await (0, _ajax.ajax)('/custom/subcategories_and_topics');
        this.set('subcategoriesAndTopics', data);
      } catch (error) {
        console.error('Error fetching subcategories and topics:', error);
      }
    }
    generateSubcategoryTopicData() {
      if (!this.subcategoriesAndTopics || !this.topicScores) return;
      let subcategoryData = [];
      Object.keys(this.subcategoriesAndTopics).forEach(subcategoryName => {
        let topics = this.subcategoriesAndTopics[subcategoryName].topics.map(topicId => {
          return this.topicScores[topicId] ? {
            ...this.topicScores[topicId],
            id: topicId
          } : null;
        }).filter(Boolean);
        if (topics.length > 0) {
          subcategoryData.push({
            subcategoryName,
            topics
          });
        }
      });
      this.set('subcategoryTopicData', subcategoryData);
      // console.log(this.subcategoryTopicData);
    }
    fetchAllTopicScores() {
      if (!this.model?.username) {
        return;
      }
      return (0, _ajax.ajax)(`/custom_search/fetch-all-topic-scores/${this.model.username}`).then(response => {
        if ($.isEmptyObject(response.scores)) {
          this.set('topicScores', null);
        } else {
          this.set('topicScores', response.scores);
        }
      }).catch(error => {
        console.error('Error fetching topic scores:', error);
      });
    }
    fetchEvaluationFeedback() {
      if (!this.model?.username) {
        return;
      }
      return (0, _ajax.ajax)(`/custom_search/fetch-user-evaluation-scores?username=${this.model.username}`).then(response => {
        if (response.evaluations) {
          this.set('evaluations', response.evaluations);
        } else {
          this.set('evaluations', null);
        }
      }).catch(error => {
        console.error('Error fetching evaluation feedback:', error);
      });
    }
    showEvaluationLogs(evaluationData) {
      if (evaluationData && Array.isArray(evaluationData) && evaluationData[0].feedback) {
        this.popupContent = this.formatEvaluationData(evaluationData);
        this.set('showPopup', true);
      } else {
        this.popupContent = '<p>No evaluation data available</p>';
        this.set('showPopup', true);
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "showEvaluationLogs", [_object.action]))();
    isFeedbackVisible(topicId) {
      return this.topicScores[topicId] && this.topicScores[topicId].evaluationVisibility;
    }
    async toggleFeedbackVisibility(topicId) {
      try {
        const response = await (0, _ajax.ajax)(`/custom_search/toggle_feedback_visibility`, {
          type: "POST",
          data: {
            topic_id: topicId
          }
        });
        if (response.success) {
          let updatedData = this.subcategoryTopicData.map(subcategory => {
            let updatedTopics = subcategory.topics.map(topic => {
              if (topic.id === topicId) {
                return {
                  ...topic,
                  evaluationVisibility: response.visibility
                };
              }
              return topic;
            });
            return {
              ...subcategory,
              topics: updatedTopics
            };
          });
          this.subcategoryTopicData = updatedData;
        } else {
          console.error('Failed to update visibility.');
        }
      } catch (error) {
        console.error('Error toggling feedback visibility:', error);
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "toggleFeedbackVisibility", [_object.action]))();
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        requestAnimationFrame(() => {
          this.drawPlot();
        });
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "toggleDropdown", [_object.action]))();
    closePopup() {
      this.set('showPopup', false);
    }
    static #_10 = (() => dt7948.n(this.prototype, "closePopup", [_object.action]))();
    drawPlot() {
      const canvas = document.getElementById('tacksUsagePlot');
      const container = canvas.parentElement;
      const ctx = canvas.getContext('2d');
      const dpr = window.devicePixelRatio || 1;
      const displayWidth = container.clientWidth;
      const displayHeight = container.clientHeight;
      canvas.width = displayWidth * dpr;
      canvas.height = displayHeight * dpr;
      ctx.scale(dpr, dpr);
      const filteredLogs = this.logs.filter(log => log.points_change < 0 && log.reason !== 'Admin').map(log => ({
        date: new Date(log.created_at),
        tacks: Math.abs(log.points_change)
      }));
      if (filteredLogs.length === 0) {
        return;
      }
      filteredLogs.sort((a, b) => a.date - b.date);
      const startDate = filteredLogs[0].date;
      const endDate = new Date();
      const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
      let dateLabels = [];
      let timeUnit = '';
      if (totalDays > 60) {
        timeUnit = 'month';
        let currentMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        while (currentMonth <= endDate) {
          dateLabels.push(currentMonth.toLocaleString('default', {
            month: 'short',
            year: 'numeric'
          }));
          currentMonth.setMonth(currentMonth.getMonth() + 1);
        }
      } else {
        timeUnit = 'week';
        let currentWeek = new Date(startDate);
        currentWeek.setDate(currentWeek.getDate() - currentWeek.getDay());
        while (currentWeek <= endDate) {
          dateLabels.push(currentWeek.toLocaleDateString());
          currentWeek.setDate(currentWeek.getDate() + 7);
        }
      }
      const tacksData = dateLabels.map(label => {
        let totalTacks = 0;
        filteredLogs.forEach(log => {
          const logDateLabel = timeUnit === 'month' ? log.date.toLocaleString('default', {
            month: 'short',
            year: 'numeric'
          }) : log.date.toLocaleDateString();
          if (logDateLabel === label) {
            totalTacks += log.tacks;
          }
        });
        return totalTacks;
      });
      ctx.clearRect(0, 0, displayWidth, displayHeight);
      ctx.strokeStyle = '#36A2EB';
      ctx.lineWidth = 2;
      ctx.fillStyle = '#36A2EB57';
      const padding = 40;
      const chartHeight = displayHeight - 2 * padding;
      const chartWidth = displayWidth - 2 * padding;
      const maxTacks = Math.max(...tacksData);
      const minTacks = 0;
      const xStep = chartWidth / (dateLabels.length - 1);
      const yScale = chartHeight / (maxTacks - minTacks);
      ctx.beginPath();
      ctx.moveTo(padding, padding);
      ctx.lineTo(padding, displayHeight - padding);
      ctx.lineTo(displayWidth - padding, displayHeight - padding);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(padding, displayHeight - padding - (tacksData[0] - minTacks) * yScale);
      for (let i = 1; i < tacksData.length; i++) {
        const x = padding + i * xStep;
        const y = displayHeight - padding - (tacksData[i] - minTacks) * yScale;
        ctx.lineTo(x, y);
      }
      ctx.stroke();
      for (let i = 0; i < tacksData.length; i++) {
        const x = padding + i * xStep;
        const y = displayHeight - padding - (tacksData[i] - minTacks) * yScale;
        ctx.beginPath();
        ctx.arc(x, y, 3, 0, 2 * Math.PI);
        ctx.fill();
      }
      ctx.fillStyle = '#000';
      ctx.textAlign = 'center';
      for (let i = 0; i < dateLabels.length; i++) {
        const x = padding + i * xStep;
        ctx.fillText(dateLabels[i], x, displayHeight - padding + 20);
      }
      ctx.textAlign = 'right';
      ctx.textBaseline = 'middle';
      for (let i = 0; i <= 5; i++) {
        const yValue = minTacks + i / 5 * (maxTacks - minTacks);
        const y = displayHeight - padding - (yValue - minTacks) * yScale;
        ctx.fillText(Math.round(yValue), padding - 10, y);
      }
    }
    toggleSkillsVisibility(topicId) {
      this.expandedSkills = {
        ...this.expandedSkills,
        [topicId]: !this.expandedSkills[topicId]
      };
    }
    static #_11 = (() => dt7948.n(this.prototype, "toggleSkillsVisibility", [_object.action]))();
    formatEvaluationData(evaluationData) {
      if (!Array.isArray(evaluationData)) {
        return (0, _template.htmlSafe)('<p>Invalid evaluation data</p>');
      }
      let content = '<div class="evaluation-content-user-info-panel">';
      evaluationData.forEach(evaluation => {
        content += `
        <div class="evaluation-title-user-info-panel"><strong>Feedback</strong></div>
        <div class="feedback-user-info-panel">
          ${this.formatFeedback(evaluation.feedback)}
        </div>`;
      });
      content += '</div>';
      return (0, _template.htmlSafe)(content);
    }
    formatFeedback(feedback) {
      const feedbackItems = feedback.split(/(\d+\.\s)/).filter(Boolean);
      let formattedFeedback = '';
      for (let i = 0; i < feedbackItems.length; i += 2) {
        const number = feedbackItems[i].trim();
        const text = feedbackItems[i + 1].trim();
        formattedFeedback += `<p class="feedback-number-user-info-panel"><strong>${number}</strong> ${text}</p>`;
      }
      return formattedFeedback;
    }
    generateChartData(logs) {
      let chartData = `[chart type="line" borderColors="#36A2EB" backgroundColors="#36A2EB57" title="Tack Usage Over Time" xAxisTitle="Date" yAxisTitle="Tacks"]\n`;
      chartData += `Date | Tacks\n`;
      logs.forEach(log => {
        chartData += `${this.formatDate(log.created_at, 'YYYY-MM-DD')} | ${log.updated_points}\n`;
      });
      chartData += `[/chart]`;
      this.chartData = chartData;
    }
    formatDate(date, format) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };
      const dateObj = new Date(date);
      return dateObj.toLocaleDateString(undefined, options);
    }
  }
  _exports.default = AnalyticsController;
});