define("discourse/plugins/stemaway-ui-addons/discourse/helpers/extract-topic-id", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractTopicId = extractTopicId;
  function extractTopicId(_ref) {
    let [reason] = _ref;
    const match = reason.match(/\(Topic\s*-\s*(\d+)\)/);
    return match ? match[1] : null;
  }
  var _default = _exports.default = (0, _helper.helper)(extractTopicId);
});